import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = (props) => (
    <nav id="menu">
        <div className="inner">
            <ul className="links">
                <li><Link onClick={props.onToggleMenu} to="/">Work</Link></li>
                {/* <li><Link onClick={props.onToggleMenu} to="/blog">Blog</Link></li> */}
                <li><Link onClick={props.onToggleMenu} to="/about">Meet me</Link></li>
                <hr />
                <ul className="icons">
                <li><a rel="noreferrer" target="_blank" href="https://twitter.com/maryojo_mo" className="icon alt fa-twitter"><span className="label">Twitter</span></a></li>
                <li><a rel="noreferrer" target="_blank" href="https://www.behance.net/maryojo" className="icon alt fa-behance"><span className="label">Behance</span></a></li>
                {/* <li><a href="#" className="icon alt fa-behance"><span className="label">Behance</span></a></li> */}
                <li><a rel="noreferrer" target="_blank" href="https://github.com/maryojo" className="icon alt fa-github"><span className="label">GitHub</span></a></li>
                <li><a rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/mary-ojo/" className="icon alt fa-linkedin"><span className="label">LinkedIn</span></a></li>
            </ul>
            </ul>
        </div>
        <a className="close" onClick={props.onToggleMenu} href="javascript:;">Close</a>
    </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
